<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-row>
      <v-col>
        <base-tab-panel
            :module_name="page.name"
            :record="record"
            @change="save()"
            @archive="archive()"
            :loading="loading"
            :model="page.model">
          <template v-slot:main_fields>
            <template v-for="field in usual_fields">
              <base-field
                  :isDisabled="!canEdit(record.id || 0)"
                  :key="field.name"
                  :value="record[field.name]"
                  :field="field"
                  @change="usualFieldDataChanged"/>
            </template>
            <base-tags v-if="tags_required" :isDisabled="!canEdit(record.id || 0)" v-model="record.tags" :modal_name="page.name"/>
          </template>
          <template v-if="custom_fields.length > 0" v-slot:module_fields>
            <template v-if="record.custom_fields">
              <template v-for="field in custom_fields">
                <base-field
                    :isDisabled="!canEdit(record.id || 0)"
                    :key="field.name"
                    :value="record.custom_fields[field.name]"
                    :field="field"
                    @change="customFieldDataChanged"/>
              </template>
            </template>
          </template>
        </base-tab-panel>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            {{ $store.getters.translate('fields') }}
            <v-spacer/>
            <v-btn @click="createField()" color="primary">Create</v-btn>
          </v-card-title>
          <v-card-text>
            <v-data-table :headers="headers" :items="record.content" :items-per-page="100" multi-sort hide-default-footer>
              <template v-slot:item.alternates="{item}">
                <v-chip v-for="(alternate,index) in item.alternates" :key="index" class="mr-2">{{alternate}}</v-chip>
              </template>
              <template v-slot:item.required="{item}">
                <v-chip v-if="item.validators && item.validators.length > 0" class="mr-2">Yes</v-chip>
                <v-chip v-else class="mr-2">No</v-chip>
              </template>
              <template class="text-right" v-slot:item.actions="{ item }">
                <v-icon color="primary" class="mr-2" @click="editField(item)" small>mdi-pencil</v-icon>
                <v-icon color="error" @click="removeField(item.key)" small>mdi-delete</v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <BaseDraggableModal
        :name="modal_name"
        max-height="80vh"
        width="70vw"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        :ref="modal_name">
      <v-card>
        <BaseModal>
          <v-toolbar slot="header" class="draggable_selector" dark color="primary" dense flat>
            <v-btn icon @click="closeFieldDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title v-if="new_field">{{ $store.getters.translate('create_field') }}</v-toolbar-title>
            <v-toolbar-title v-else>{{ $store.getters.translate('edit_field') }}</v-toolbar-title>
            <v-spacer/>
            <v-btn text small @click="saveField()">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </v-toolbar>
          <div slot="content">
            <v-divider/>
            <v-card-text class="pt-5">
              <v-text-field label="Label" v-model="field.label"/>
              <v-combobox
                  v-model="field.alternates"
                  :label="$store.getters.translate('alternates')"
                  item-text="name"
                  item-value="id"
                  attach
                  chips
                  clearable
                  multiple
                  deletable-chips/>
              <v-select
                  v-model="field.type"
                  :items="field_types"
                  :label="$store.getters.translate('field_type')"
                  item-text="label"
                  item-value="value"
                  :return-object="false"/>
              <v-combobox
                  v-if="field.type === 'select'"
                  v-model="field.options"
                  :items="equipment_options"
                  item-text="label"
                  item-value="value"
                  :label="$store.getters.translate('field_options')"
                  attach
                  chips
                  clearable
                  multiple
                  deletable-chips
                  return-object/>
              <v-btn-toggle v-model="field.validation_required">
                <v-btn :value="true">{{ $store.getters.translate('required') }}</v-btn>
                <v-btn :value="false">{{ $store.getters.translate('not_required') }}</v-btn>
              </v-btn-toggle>
            </v-card-text>
          </div>
        </BaseModal>
      </v-card>
    </BaseDraggableModal>
  </v-container>
</template>

<script>
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import BaseTabPanel from "../../components/commonComponents/BaseTabPanel";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    BaseBreadcrumb,
    BaseTabPanel,
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      modal_name: 'show_create_field',
      page: {
        title: this.$store.getters.translate("fieldsets"),
        name: "fieldsets",
        model: "fieldset",
      },
      record: {
        content: [],
      },
      usual_fields: [],
      custom_fields: [],
      tags_required: false,
      loading: false,
      field_types: [
        { value: "select", label: "Select" },
        { value: "string", label: "Text" }
      ],
      field: {},
      new_field: null,
      headers: [
        { text: "Key", value: "key", sortable: true, align: "start" },
        { text: "Label", value: "label" },
        { text: "Alternates", value: "alternates" },
        { text: "Required", value: "required" },
        { text: "Delete", value: "actions", sortable: false, align: "right" }
      ],
      equipment_options: []
    };
  },
  created() {
    this.load();
    this.getEquipment();
  },
  methods: {
    ...helpFunctions.modal_functions,
    async load() {
      this.loading = true;
      this.record = await helpFunctions.loadModel(this.$toasted, this.page.name, this.$route.params.id);
      await this.getFields();
    },
    async save() {
      if(!this.loading) {
        if (helpFunctions.modelFieldsFilled(this.$toasted, this.record, this.usual_fields)) {
          this.loading = true;
          if(await helpFunctions.saveModel(this.$toasted, this.page.name, this.record)) {
            await this.load();
          }
          this.loading = false;
        }
      }
    },
    async archive() {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then(async (res) => {
        if (res) {
          this.loading = true;
          if (await helpFunctions.deleteModel(this.$toasted, this.page.name, this.record.id)) {
            await this.$router.push("/" + this.page.name);
          }
          this.loading = false;
        }
      });
    },
    createField() {
      this.field = {
        label: "",
        key: "",
        type: "string",
        alternates: [],
        validators: [],
        validation_required: false
      };
      this.new_field = true;
      this.openModal(this.modal_name);
    },
    editField(field) {
      this.field = field;
      if(this.field.validators && this.field.validators.length > 0) {
        this.field.validation_required = true;
      }
      else {
        this.field.validation_required = false;
      }
      this.new_field = false;
      this.openModal(this.modal_name);
    },
    removeField(key) {
      this.record.content = this.record.content.filter(field => field.key != key);
      this.save();
    },
    saveField() {
      this.field.key = this.field.label.replace(/ /g, "_").toLowerCase();
      if(this.field.validation_required) {
        this.field.validators = [{ validate: "required" }];
      }
      else {
        this.field.validators = [];
      }
      delete this.field.validation_required;
      if(this.new_field) {
        this.record.content.push(this.field);
      }
      this.closeModal(this.modal_name);
      this.save();
    },
    closeFieldDialog() {
      this.closeModal(this.modal_name);
    },
    getEquipment() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/equipment")
          .then((response) => {
            this.loading = false;
            response.data.data.forEach(row => {
              this.equipment_options.push({
                value: row.name.toLowerCase(),
                label: row.name
              });
            });
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    usualFieldDataChanged(data) {
      this.record[data[0]] = data[1];
    },
    customFieldDataChanged(data) {
      this.record.custom_fields[data[0]] = data[1];
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.page.model, "show_on_update");
      this.tags_required = response.tags_required;
      this.usual_fields = response.usual_fields;
      this.custom_fields = response.custom_fields;
      this.loading = false;
    },
    canEdit(id = 0) {
      return this.$can("edit", this.$createEntity(this.page.model, {id}));
    },
  },
};
</script>